<template>
  <div class="church-list template-1">
    <header class="page-header container">
      <h1>{{ translations.tcPlacementLocations }}</h1>
    </header>
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <camp-selector @camp_changed="page_load($event)" :tooltip="translations.tcTooltip"
        :i18n="translations.components"></camp-selector>
      <data-table :fields="dataTableFields" :items="placementLocationsTranslated" :hiddenItems="hiddenItems"
        :addItems="addItems" @addLocation="addLocation" :csvUrl="locationListCSVUrl" :pdfUrl="locationListPDFUrl"
        :reportName="reportName" :i18n="translations.components"></data-table>
      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTableScripture.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'placement-locations',
  mixins: [translationMixin],
  data() {
    return {
      addItems: {
        display: true,
        text: 'Add Location',
        action: 'addLocation',
      },
      dataTableFields: [
        {
          key: 'facility_name',
          label: '', // 'Name'
          sortable: true,
          thClass: 'locname-left',
        },
        {
          key: 'institution_type',
          label: '', // 'Institution Type'
          sortable: true,
        },
        {
          key: 'camp_assigned_number',
          label: '', // 'Assigned Number'
          sortable: true,
        },
        {
          key: 'member_type',
          label: '', // 'Member Type'
          sortable: true,
        },
        {
          key: 'city',
          label: '', // 'City'
          sortable: true,
        },
        {
          key: 'last_presentation_date',
          label: '', // 'Last Presentation'
          sortable: true,
          formatter: (value, key, item) => {
            return !!value ? value.slice(0, 10) : ''
          },
        },
      ],
      hiddenItems: {
        display: false,
      },
      placementLocationsTranslated: [],
      reportName: 'PlacementLocations',
      secured_controls: {
        add_scripture_distribution_location_button: '18533130-6c49-4dd0-9a10-ce18c3e115c1',
      },
      selectedMemberType: '9084a3c7-bbf0-4796-b4a9-5b5d95349d4c', //DEFAULT TO GET ALL
      translations: {},
    }
  },
  methods: {
    ...mapActions({
      clearLocationDetails: 'scriptureDistribution/clearLocationDetails',
      loadAddressFormOptions: 'membership/loadAddressFormOptions',
      loadChurchListReportUrls: 'churchMinistry/loadChurchListReportUrls',
      loadPlacementLocationsByCamp: 'scriptureDistribution/loadPlacementLocationsByCamp',
      loadPlacementLocationsByCampAsCSVUrl: 'scriptureDistribution/loadPlacementLocationsByCampAsCSVUrl',
      loadPlacementLocationsByCampAsPDFUrl: 'scriptureDistribution/loadPlacementLocationsByCampAsPDFUrl',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedLocationKey: 'user/setSelectedLocationKey',
      setRouterBackLink: 'user/setRouterBackLink',
    }),
    async page_load(evt) {
      if (!evt) { // execute only if evt is undefined
        try {
          await this.setLoadingStatus(true)
          await Promise.all([
            await this.setSelectedLocationKey(''),
            await this.loadPlacementLocationsByCamp(this.selectedMemberType),
            await this.loadPlacementLocationsByCampAsPDFUrl(this.selectedMemberType),
            await this.loadPlacementLocationsByCampAsCSVUrl(this.selectedMemberType),
            await this.loadChurchListReportUrls({ hidden: null, ineligible: null }),
            this.translateTextForDataTable()
          ]).then(() => true)
        } catch (e) {
          console.error(e)
        } finally {
          await this.setLoadingStatus(false)
        }
      }
    },
    async addLocation() {
      try {
        await Promise.all([
          this.setLoadingStatus(true),
          await this.setSelectedLocationKey(''),
          await this.clearLocationDetails(),
          await this.loadAddressFormOptions(),
        ]).then(() => {
          this.$router.push('/programs/sd/location-profile/add-location/link')
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    loadTranslations() {
      this.addItems.text = this.translations.tcAddLocation
      this.loadDataTableFields()
    },
    loadDataTableFields() {
      // The dataTableFields object is init in object to precent
      // default behavior of shwoing all fields when 'fields: prop is empty.
      // Instead we set 'label' attribute to be empty in each object and then
      // set the translated value below.
      this.dataTableFields[0].label = this.translations.tcName
      this.dataTableFields[1].label = this.translations.tcInstitutionType
      this.dataTableFields[2].label = this.translations.tcAssignedNumber
      this.dataTableFields[3].label = this.translations.tcMemberType
      this.dataTableFields[4].label = this.translations.tcCity
      this.dataTableFields[5].label = this.translations.tcLastPresentationDate
    },
    translateTextForDataTable() {
      return (this.placementLocationsTranslated = this.placement_locations.map((location) => {
        if (!location || !location.mpt_key || !location.institution_type_key || !this.translations.common)
          return location
        const memberType = this.translations.common['member-types'].hasOwnProperty(location.mpt_key)
          ? this.translations.common['member-types'][location.mpt_key]
          : location.member_type

        const institutionType = this.translations.common['placement-facility-types'].hasOwnProperty(
          location.institution_type_key
        )
          ? this.translations.common['placement-facility-types'][location.institution_type_key]
          : location.institution_type

        return { ...location, institution_type: institutionType, member_type: memberType }
      }))
    },
  },
  computed: {
    ...mapGetters({
      iCanSeeAny: 'user/iCanSeeAny',
      isInMenu: 'menu/isInMenu',
      locationListCSVUrl: 'scriptureDistribution/locationListCSVUrl',
      locationListPDFUrl: 'scriptureDistribution/locationListPDFUrl',
      placement_locations: 'scriptureDistribution/placement_locations',
      prefCulture: 'user/userPreferredCulture',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      this.addItems.display = this.iCanSeeAny(this.secured_controls)
      await Promise.all([
        await this.getViewTranslations(),
        await this.getComponentTranslations('data-table-scripture', 'security-banner', 'camp-select'),
        await this.getComponentTranslations('common.member-types', 'common.placement-facility-types'),
      ]).then((results) => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common },
        }
        this.$set(this.translations, 'components', translatedText)
        this.translateTextForDataTable()
        this.loadTranslations()
        this.page_load()
      }).then(() => true)
    } catch (e) {
      console.error(e)
    } finally {
      await this.setLoadingStatus(false)
    }
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
    securityBanner: SecurityBanner,
  },
}
</script>

<style lang="scss" scoped>
/* eslint-disable */
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';
</style>
